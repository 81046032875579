<script setup lang="ts">
import type { CarbonCredit, DataDictionary } from '@/model/model';
import { computed, onMounted, type PropType } from 'vue';
import CarbonCreditImage from './CarbonCreditImage.vue';
import { useCarbonCreditStore } from '@/stores/modules/carbonCredit';

const carbonCreditStore = useCarbonCreditStore()
const props = defineProps({
    item: {
        type: Object as PropType<CarbonCredit>,
        required: true
    } 
})
const registry = computed(() => {
    return props.item.other_registry.length > 0 ? props.item.other_registry : carbonCreditStore.registryList.find((item:DataDictionary) => item.value === props.item.registry)?.name
})
const category = computed(() => {
    return carbonCreditStore.categoryList.find((item:DataDictionary) => item.value === props.item.category)?.name
})
const carbonType = computed(() => {
    return carbonCreditStore.carbonTypeList.find((item:DataDictionary) => item.value === props.item.carbon_credit_type)?.name
})
const country = computed(() => {
    return props.item.other_country.length > 0 ? props.item.other_country : carbonCreditStore.countryList.find((item:DataDictionary) => item.value === props.item.country_of_origin)?.name
})

function onDetailClick(){
    window.location.href = '/carbon/detail?id=' + props.item.id + '&from=cclisting'
}

onMounted(() => {
    carbonCreditStore.initDataDictionary()
})
</script>

<template>
    <div class="flex flex-wrap py-4 border-b hover:bg-gray-100" @click="onDetailClick">
        <CarbonCreditImage class="w-16 h-16 ml-4 rounded" :category="item.category"/>
        <div class="flex-1 ml-4 self-center">
            <p class="sm:text-xl font-semibold">{{ item.project_name }}</p>
            <div class="flex flex-wrap">
                <p class="text-xs sm:bg-gray-200 text-black sm:px-2 sm:py-1 rounded mt-1">{{ item.vintage_year }}<span v-if="item.vintage_month.length > 0">/{{ item.vintage_month }}</span></p>
                <p class="text-xs sm:bg-gray-200 text-black px-2 sm:py-1 rounded mt-1 ml-1">{{ country }}</p>
                <p class="text-xs sm:bg-gray-200 text-black sm:px-2 sm:py-1 rounded mt-1 ml-1">{{ carbonType }}</p>
            </div>
            <div class="text-mainGreen sm:hidden">US$ {{ (item.price/100).toFixed(2) }}</div>
        </div>
        <div class="self-center hidden sm:block">
            <p class="text-xl font-semibold">US$ {{ (item.price/100).toFixed(2) }}</p>
        </div>
    </div>
</template>