import { mande } from 'mande';
import {
    API_Register,
    API_Login,
    API_Verify2StepCode,
    API_UPDATE_WALLET_ADDRESS,
    API_VERIFY_EMAIL,
    API_VERIFY_EMAIL_RESEND,
    API_VERIFY_EMAIL_SEND,
    API_SubUser_ChgPwd,
    API_User_ChgPwd,
    API_CHECK_LIANLIAN_ID,
    API_Refresh_token,
    API_SEACH_USER_TRANSACTIONS,
    API_Email_Exist,
    API_GET_USER_KYC_STATUS,
    API_SubUser_Create,
    API_SubUsers,
    API_SubUser_Delete,
    API_VERIFY_FORGET_EMAIL, API_User_Retrieve_PWD, API_USER_TRANSACTION_EXPORT, API_LIANLIAN, API_CAPTCHA
} from './../utils/const'
import type {
    BaseResponse,
    RegisterRequest,
    LoginRequest,
    Verify2FARequest,
    Verify2FAResponseData,
    UpdateWalletAddressRequest,
    UserChangePwdRequest,
    CheckLianLianIdRequest,
    CheckLianLianIdResponse,
    UserNFTSstatic,
    EmailVerifyRequest,
    SearchUserTransactionRequest,
    SearchUserTransactionResponse,
    UpdateWalletAddressResponse, DeleteSubUserRequest, ForgetPwdRequest, ReportUserTransactionRequest, UserLianLianResponse
} from './../model/reqRes'
import type {UserTransaction, User} from '@/model/model'
import {ContentTypeEnum} from '@/utils/enums/httpEnum'

const registerApi = () => mande(API_Register);
const captchaApi = () => mande(API_CAPTCHA);
const loginApi = () => mande(API_Login);
const verify2faApi = () => mande(API_Verify2StepCode);
const updateWalletAddressApi = () => mande(API_UPDATE_WALLET_ADDRESS);
const verifyEmailApi = () => mande(API_VERIFY_EMAIL);
const verifyForgetEmailApi = () => mande(API_VERIFY_FORGET_EMAIL);
const resendEmailOTPApi = () => mande(API_VERIFY_EMAIL_RESEND);
const sendEmailOTPApi = () => mande(API_VERIFY_EMAIL_SEND);
const changeSubPwd = () => mande(API_SubUser_ChgPwd);
const changePwd = () => mande(API_User_ChgPwd);
const userLianLian = () => mande(API_CHECK_LIANLIAN_ID);
const userRefreshToken = () => mande(API_Refresh_token);
const userTransactionSearchApi = () => mande(API_SEACH_USER_TRANSACTIONS)
const emailExist = () => mande(API_Email_Exist)
const userKycStatusApi = () => mande(API_GET_USER_KYC_STATUS)
const deleteSub = () => mande(API_SubUser_Delete)
const retrievePwd = () => mande(API_User_Retrieve_PWD)
const userTransactionReportApi = () => mande(API_USER_TRANSACTION_EXPORT)
const userLianLianApi = () => mande(API_LIANLIAN)

export async function getCaptcha() {
  return await captchaApi().get<any>('').catch((error) => {
    return null;
  });
}

export async function userLianLianDisconnect(){
  return await userLianLianApi().get<BaseResponse<string>>('/disconnect')
  .catch((error) => {
    return {
        code: 500,
        message: 'server error',
        data: null
    } as BaseResponse<string>;
  });
}

export async function checkUserLianLianInfo(){
  return await userLianLianApi().get<BaseResponse<UserLianLianResponse>>('/check')
  .catch((error) => {
    return {
        code: 500,
        message: 'server error',
        data: null
    } as BaseResponse<UserLianLianResponse>;
});
}

export async function userLianLianAuth(code:string, state:string){
  return await userLianLianApi().get<BaseResponse<UserLianLianResponse>>(`/token/${code}/${state}`)
  .catch((error) => {
    return {
        code: 500,
        message: 'server error',
        data: null
    } as BaseResponse<UserLianLianResponse>;
});
}

export async function getUserLianLianAuthUrl(){
  return await userLianLianApi().get<BaseResponse<string>>('/auth')
  .catch((error) => {
    return {
        code: 500,
        message: 'server error',
        data: null
    } as BaseResponse<string>;
});
}

export async function getUserLianLianStatus(){
  return await userLianLianApi().get<BaseResponse<UserLianLianResponse>>('')
  .catch((error) => {
    return {
        code: 500,
        message: 'server error',
        data: null
    } as BaseResponse<UserLianLianResponse>;
});
}

export async function exportUserTransaction(req: ReportUserTransactionRequest){
  return await userTransactionReportApi().post<BaseResponse<UserTransaction[]>>(req)
  .catch((error) => {
    return {
        code: 500,
        message: 'server error',
        data: null
    } as BaseResponse<UserTransaction[]>;
});
}

export async function getUserKycStatus(){
  return await userKycStatusApi().get<BaseResponse<string>>("").catch((error) => {
    return {
      code: 500,
      message: 'server error',
      data: null
    } as BaseResponse<string>;
  });
}
const subuser = () => mande(API_SubUser_Create)
const sub_users = () => mande(API_SubUsers)

export async function createSubUser(req:RegisterRequest){
    return await subuser().post<BaseResponse<string>>(req)
        .catch((error) => {
            return {
                code: 500,
                message: 'server error',
                data: null
            } as BaseResponse<string>;
        });
}
export async function getSubUsers(){
    return await sub_users().get<BaseResponse<User[]>>('')
        .catch((error) => {
            return {
                code: 500,
                message: 'server error',
                data: null
            } as BaseResponse<User[]>;
        });
}


export async function searchUserTransactions(req: SearchUserTransactionRequest){
  return await userTransactionSearchApi().post<BaseResponse<SearchUserTransactionResponse>>(req)
  .catch((error) => {
    return {
      code: 500,
      message: 'server error',
      data: null
    } as BaseResponse<SearchUserTransactionResponse>;
  });
}

export async function checkLianLianId(req: CheckLianLianIdRequest) {
  return await userLianLian()
    .post<BaseResponse<CheckLianLianIdResponse>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<CheckLianLianIdResponse>;
    });
}

export async function getUserById(id: number) {
  return await registerApi()
    .get<BaseResponse<UserNFTSstatic>>(id)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<UserNFTSstatic>;
    });
}

export async function createUser(user: RegisterRequest) {
  return await registerApi()
    .post<BaseResponse<string>>(user)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}

export async function userLogin(req: LoginRequest) {
  return await loginApi()
    .post<BaseResponse<string>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}

export async function verify2FA(req: Verify2FARequest) {
  return await verify2faApi()
    .post<BaseResponse<Verify2FAResponseData>>(req)
    .catch((err) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<Verify2FAResponseData>;
    });
}

export async function updateWalletAddress(req: UpdateWalletAddressRequest) {
  return await updateWalletAddressApi()
    .post<BaseResponse<UpdateWalletAddressResponse>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<UpdateWalletAddressResponse>;
    });
}

export async function verifyEmail(req: EmailVerifyRequest) {
  return await verifyEmailApi()
    .post<BaseResponse<Verify2FAResponseData>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<Verify2FAResponseData>;
    });
}

export async function verifyForgetEmail(req: EmailVerifyRequest) {
    return await verifyForgetEmailApi(req)
        .post<BaseResponse<Verify2FAResponseData>>(req)
        .catch((error) => {
            return {
                code: 500,
                message: 'server error',
                data: null
            } as BaseResponse<Verify2FAResponseData>;
        });
}

export async function resendEmailOTP() {
  return await resendEmailOTPApi()
    .get<BaseResponse<string>>('')
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}

export async function sendEmailOTP(req: LoginRequest) {
    return await sendEmailOTPApi()
        .post<BaseResponse<string>>(req)
        .catch((error) => {
            return {
                code: 500,
                message: 'server error',
                data: null
            } as BaseResponse<string>;
        });
}

export async function changeUserPassword(req: UserChangePwdRequest) {
  return await changePwd()
    .post<BaseResponse<string>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}

export async  function retrievePassword(req: ForgetPwdRequest) {
    return await retrievePwd()
        .post<BaseResponse<string>>(req)
        .catch((error) => {
            return {
                code: 500,
                message: 'server error',
                data: null
            } as BaseResponse<string>;
        });
}

export async function changeSubPassword(req: UserChangePwdRequest) {
  return await changeSubPwd()
    .post<BaseResponse<string>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}

export async function refreshToken() {
  return await userRefreshToken()
    .post<BaseResponse<Verify2FAResponseData>>()
    .catch((err) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<Verify2FAResponseData>;
    });
}
export async function checkEmailExist(req:LoginRequest) {
    return await emailExist().post<BaseResponse<boolean>>(req).catch((err) => {
        return {
            code: 500,
            message: 'server error',
            data: null
        } as BaseResponse<boolean>;
    });
}

export async function deleteSubUser (userId:number) {
    return await deleteSub().delete<BaseResponse<string>>(userId.toString()).catch((err) => {
        return {
            code: 500,
            message: 'server error',
            data: null
        } as BaseResponse<boolean>;
    });
}