import { createApp } from 'vue';
// @ts-ignore
import Chat from 'vue3-beautiful-chat';

import App from './App.vue';
import router from './router';
import pinia from './stores';
import './assets/tailwind.css';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import fetchIntercept from 'fetch-intercept';
import { setGlobalToken } from '@/api/global';
import { useAccountStore } from '@/stores/modules/account';
import { useRouterStore } from './stores/modules/router';

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(Chat);
app.use(Toast);
app.mount('#app');

const accountStore = useAccountStore();
fetchIntercept.register({
  request: function (url, config) {
    return [url, config];
  },

  requestError: function (error) {
    return Promise.reject(error);
  },
  responseError: function (error) {
    return Promise.reject(error);
  },
  response: function (response) {
    console.log('check auth:', response.url);
    if (response.status === 401) {
      if (
        response.url.includes('rec-server/client/email/verify') ||
        response.url.includes('rec-server/client/email/resend')
      ) {
        console.log('-----finally, token expired---, re login');
        router.push('/login').then((r) => {});
      } else {
        console.log(
          '-----token expired---, now use refresh_token and redirect to email verify, token:',
          accountStore.refresh_token
        );
        if (accountStore.refresh_token.length > 0) {
          setGlobalToken(accountStore.refresh_token);
          if (accountStore.remember) {
            router.push('/email/verify?remember=true').then((r) => {});
          } else {
            router.push('/email/verify?remember=false').then((r) => {});
          }
        } else {
          console.log('-----refresh none--- , re login');
          router.push('/login').then((r) => {});
        }
      }
    }
    return response;
  }
});
