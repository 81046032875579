<script lang="ts" setup>
import { getUserLianLianAuthUrl } from '@/api/user';
import SimpleModal from '@/components/control/simpleModal.vue'
import { useAccountStore } from '@/stores/modules/account';
import { useWeb3Store } from '@/stores/modules/web3';
import { onMounted, ref } from '@vue/runtime-core';
import { useToast } from 'vue-toastification';

const accountStore = useAccountStore()
const targetWindow = ref<Window>(window)
const bindClick = ref<boolean>(false)

function onconfirm(){
    accountStore.showPaymentBindingModal = false;
}

async function onclick(){
    if(accountStore.lianlian_id.length > 0) {
        const toast = useToast()
        toast.info('You have already bound LianLian Pay')
        accountStore.showPaymentBindingModal = false
        location.href = '/person/profile?tab=Profile'
        return 
    }
    bindClick.value = true
    //targetWindow.value.open(accountStore.agreementUrl, "_blank")
    const resp = await getUserLianLianAuthUrl()
    console.log(resp)
    if(resp.code == 200){
        window.location.href = resp.data!
    } else {
        const toast = useToast()
        toast.error("Get Lianlian auth url failed")
    }
    bindClick.value = true
}

async function onconnectclick(){
    var pass = await accountStore.checkPaymentReady(window.location.href)
    if(pass){
        bindClick.value = false
        accountStore.showPaymentBindingModal = false
    } else {
        bindClick.value = false
    }
}


onMounted(() => {
  console.log(accountStore.showPaymentBindingModal)  
})
</script>

<template>
    <SimpleModal :open="accountStore.showPaymentBindingModal" title="LianLian Pay Binding" :show-footer="false" @confirm="onconfirm" @close="onconfirm">
        <template v-slot:content>
            <div class="items-center block max-w-lg p-6 mx-auto mt-6 text-center">
                <img class="h-24 mx-auto" src="/images/transfer.png"/>
                <div>
                    <p class="mt-6 text-xl font-semibold">Authorization Agreement</p>
                    <p class="mt-6 text-left">By clicking “Accept” you hereby grant permission to GREX to access and utilize Lianlian Pay (LLP) as a third-party payment gateway on your behalf. 
This authorization enables GREX </p>
                    <p class="text-left mt-2">1. to process payments, including the collection and storage of necessary payment information, for the products or services you purchase on the website.</p>
                    <p class="text-left mt-2">2.  to transmit ypur payment details to LLP for the purpose of completing transactions. You acknowledge that GREX will not have access to your full LLP account details, as they will be securely stored and processed by LLP.</p>
                    <div class="flex items-center justify-center mt-6">
                        <button @click="onclick" :disabled="bindClick" class="px-8 py-2 text-white rounded-lg bg-mainGreen disabled:bg-gray-300">
                            Accept
                        </button>
                        <button @click="onconfirm" class="px-8 py-2 ml-12 border border-gray-300 rounded-lg">Cancel</button>
                    </div> 
                </div>
                
            </div>
        </template>
    </SimpleModal>
</template>