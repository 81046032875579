<script setup lang="ts">
import { getTopCarbonCredits } from '@/api/carbonCredit';
import type { CarbonCredit } from '@/model/model';
import { onMounted, ref } from 'vue';
import CarbonCreditTopItem from '../carboncredit/CarbonCreditTopItem.vue';

const carbonCredits = ref<CarbonCredit[]>([])

onMounted(async ()=>{
    //load top carbon credits
    const resp = await getTopCarbonCredits()
    console.log(resp)
    if(resp.code == 200){
        carbonCredits.value = resp.data!
    }
})
</script>

<template>
    <div class="px-4 mx-auto mt-12 xl:px-0 xl:max-w-7xl xl:container">
        <div class="flex">
            <div class="flex-1 text-2xl sm:text-3xl font-bold">Carbon Credit</div>
            <a class="self-end text-base text-right text-mainGreen" href="/carbon/list">
                See more >
            </a>
        </div>
        <div class="grid-cols-1 mt-4 divide-y gird">
            <div></div>
            <div></div>
        </div>
        <div class="pt-0 grid lg:grid-cols-2 lg:gap-x-12">
            <CarbonCreditTopItem v-for="item in carbonCredits" :item="item"></CarbonCreditTopItem>
        </div>
    </div>
</template>