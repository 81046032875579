<script lang="ts" setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import { onMounted, ref } from '@vue/runtime-core';
import type { Banner } from '@/model/model';
import { getBanners } from '@/api/commonData';

const banners = ref<Banner[]>([] as Banner[])

async function loadBanners(){
   const resp = await getBanners()
   if(resp.code == 200){
    banners.value = resp.data!
   }
}

function gotoLink(url:string){
  window.location.href = url
}

onMounted(() => {
  loadBanners()
})
</script>

<template>
  <Carousel :autoplay="5000" :wrap-around="true" class="py-12 mx-4">
    <Slide v-for="slide in banners" :key="slide.id" class="z-0">
      <button  @click="gotoLink(slide.link)">
        <img class="object-cover h-36 md:h-auto rounded-xl" :src="slide.image" />
      </button>
    </Slide>
  </Carousel>
</template>

<style>
</style>
