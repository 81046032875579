<script lang="ts" setup>
import { getNftTops } from '@/api/nft';
import type { RecNftOrder } from '@/model/model';
import { onMounted, ref } from '@vue/runtime-core';
import NftTopItem from '../nft/NftTopItem.vue';

const listData = ref<RecNftOrder[]>([])
onMounted(async () => {
  const resp = await getNftTops()
  if(resp.code == 200){
    listData.value = resp.data!
  }
})
</script>

<template>
  <div class="px-4 mx-auto mt-0 xl:container xl:px-0 xl:max-w-7xl">
    <div class="flex">
      <div class="flex-1 text-3xl font-bold">REC NFT</div>
      <a class="self-end text-base text-right text-mainGreen" href="/nft/market">
        See more >
      </a>
    </div>
    <div class="grid-cols-1 mt-4 divide-y gird">
      <div></div>
      <div></div>
    </div>
    <div
      class="pt-8 xl:grid-cols-4 md:grid md:grid-cols-2 md:gap-x-12 md:gap-y-6 lg:grid-cols-3"
    >
      <NftTopItem v-for="item in listData" :item="item"></NftTopItem>
    </div>
  </div>
</template>
