

export function addressToShort(address: string) {
    if(address==undefined || address.length == 0) return '0x000'
    return address?.substring(0, 5) + '...' + address.substring(address.length - 4)
}

export function dateTimeStringToDate(date: string) {
    if(date == undefined) return ''
    try{
        var dt = Date.parse(date)
        console.log(dt)
        return getFormattedDate(dt)
    }catch(err){
        console.log(err)
        return ''
    }
}

export function makeNoce(length: number) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function getFormattedDate(dateNumber: number) {
    var date = new Date(dateNumber)
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();

    var monthStr = (month < 10 ? "0" : "") + month.toString();
    var dayStr = (day < 10 ? "0" : "") + day.toString();
    var hourStr = (hour < 10 ? "0" : "") + hour.toString();
    var minStr = (min < 10 ? "0" : "") + min.toString();
    var secStr = (sec < 10 ? "0" : "") + sec.toString();

    var str = date.getFullYear() + "-" + monthStr + "-" + dayStr + " " +  hourStr + ":" + minStr + ":" + secStr;

    /*alert(str);*/

    return str;
}

export function checkInputNumber(numStr: string, limit=0, max=0, isInt=0) {
    console.log(numStr)
    //if(numStr.length == 0){''
    //    return isInt ? '0' : '0.00'
    //}
   numStr = numStr.replace(/[^\d.]/g, "")
   numStr = numStr.replace(/^00/, "0.")
   numStr = numStr.replace(/^\./g, "0.")
   numStr = numStr.replace(/\.{2,}/g, ".")
   numStr = numStr.replace(".", "$#$").replace(/\./g, "").replace("$#$",".");
   /^0\d+/.test(numStr) ? numStr = numStr.slice(1) : '' ;
   const str = '^(\\d+)\\.(\\d{' + limit + '}).*$'
   const reg = new RegExp(str)
   if (limit === 0) {
    numStr = numStr.replace(reg, '$1')
   } else {
    numStr = numStr.replace(reg, '$1.$2')
   }
   if(max > 0 && Number.parseInt(numStr) > max){
    numStr = max.toString()
   }
   return numStr
}

export function checkLatitude(value: string) {
    const reg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
    if(value === ""){
        return false
    }else{
        if(!reg.test(value)){
            return false 
        }
    }
    return true
}

export function checkLongitude(value: string) {
    const reg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
    if(value === ""){
        return false
    }else{
        if(!reg.test(value)){
            return false 
        }
    }
    return true
}