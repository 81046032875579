import { getDataDictionary } from "@/api/commonData";
import type { DataDictionary } from "@/model/model";
import { defineStore } from "pinia";
import { countries } from 'country-list-json';

export const useCarbonCreditStore = defineStore({
    id: 'carbonCreditStore',
    state: () => ({
        registryList: [] as DataDictionary[],
        categoryList: [] as DataDictionary[],
        countryList: [] as DataDictionary[],
        carbonTypeList: [] as DataDictionary[],
        goalList: [] as DataDictionary[],
        vintageYearList: [] as DataDictionary[],
        vintageMonthList: [] as DataDictionary[],
        otherCountryList: [] as string[],
        statusList: [] as DataDictionary[]
    }),
    actions: {
        async initDataDictionary() {
            var registryResp = await getDataDictionary('carbon_credit_registry')
            this.registryList = []
            registryResp.data?.forEach(item => {
                if(item.name === 'All'){
                    return
                }
                this.registryList.push(item)
            })
            var categoryResp = await getDataDictionary('carbon_credit_category')
            this.categoryList = []
            categoryResp.data?.forEach(item => {
                if(item.name === 'All'){
                    return
                }
                this.categoryList.push(item)
            })
            var countryResp = await getDataDictionary('region')
            this.countryList = []
            countryResp.data?.forEach(item => {
                if(item.name === 'All'){
                    return
                }
                this.countryList.push(item)
            })
            this.otherCountryList = []
            countries.forEach(item =>{
                var inCountry = false
                this.countryList.forEach(country => {
                    if(country.name == item.name){
                        inCountry = true
                    }
                })
                if(!inCountry){
                    this.otherCountryList.push(item.name)
                }
            })
            var carbonTypeResp = await getDataDictionary('carbon_credit_type')
            this.carbonTypeList = []
            carbonTypeResp.data?.forEach(item => {
                if(item.name === 'All'){
                    return
                }
                this.carbonTypeList.push(item)
            })
            var goalResp = await getDataDictionary('carbon_credit_goals')
            this.goalList = []
            goalResp.data?.forEach(item => {
                if(item.name === 'All'){
                    return
                }
                this.goalList.push(item)
            })
            var vintageYearResp = await getDataDictionary('vintage_year')
            this.vintageYearList = []
            vintageYearResp.data?.forEach(item => {
                if(item.name === 'All'){
                    return
                }
                this.vintageYearList.push(item)
            })
            var vintageMonthResp = await getDataDictionary('vintage_month')
            this.vintageMonthList = []
            vintageMonthResp.data?.forEach(item => {
                if(item.name === 'All'){
                    return
                }
                this.vintageMonthList.push(item)
            })
            const statusResp = await getDataDictionary('carbon_credit_status')
            this.statusList = []
            statusResp.data?.forEach((item:DataDictionary) => {
                this.statusList.push(item)
            })
        }
    }
})