import { useRouterStore } from '@/stores/modules/router';
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized
} from 'vue-router';
import HomeView from '../views/HomeView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/rec/list',
      name: 'recList',
      component: () => import('../views/RecListingView.vue')
    },
    {
      path: '/membership/plan',
      name: 'membershipPlan',
      component: () => import('../views/MembershipPlanView.vue')
    },
    {
      path: '/memebership/order',
      name: 'membershipOrder',
      component: () => import('../views/MembershipOrderView.vue')
    },
    {
      path: '/my/membership/order',
      name: 'myMembershipOrder',
      component: () => import('../views/MyMembershipOrder.vue')
    },
    {
      path: '/nft/detail',
      name: 'nftDetail',
      component: () => import('../views/NftDetail.vue')
    },
    {
      path: '/nft/token/detail',
      name: 'nftTokenDetail',
      component: () => import('../views/NFTTokenDetail.vue')
    },
    {
      path: '/nft/market',
      name: 'NFTMarket',
      component: () => import('../views/NFTMarketView.vue')
    },
    {
      path: '/nft/list/detail',
      name: 'NFTListDetail',
      component: () => import('../views/NFTListDetail.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/Register.vue')
    },
    {
      path: '/email/verify',
      name: 'emailVerify',
      component: () => import('../views/EmailVerfiy.vue')
    },
    {
      path: '/kyc',
      name: 'kyc',
      component: () => import('../views/Kyc.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/Profile.vue')
    },
    {
      path: '/person/pwd',
      name: 'changePwd',
      component: () => import('../views/ChangePasswordView.vue')
    },
    {
      path: '/person/nft',
      name: 'personNft',
      component: () => import('../views/NFTPersonGroup.vue')
    },
    {
      path: '/person/external/nfts',
      name: 'personExternalNFTs',
      component: () => import('../views/PersonExternalNFTs.vue')
    },
    {
      path: '/person/transaction',
      name: 'personTransaction',
      component: () => import('../views/TransactionPersonGroup.vue')
    },
    {
      path: '/person/profile',
      name: 'personProfile',
      component: () => import('../views/MyProfile.vue')
    },
    {
      path: '/person/cc',
      name: 'personCC',
      component: () => import('../views/CarbonCreditPersonGroup.vue')
    },
    {
      path: '/my/carbon/create',
      name: 'myCCCreate',
      component: () => import('../views/CarbonCreditCreate.vue')
    },
    {
      path: '/person/rec',
      name: 'personRec',
      component: () => import('../views/RecPersonGroup.vue')
    },
    {
      path: '/my/rec/list',
      name: 'myRecList',
      component: () => import('../views/MyRecListingView.vue')
    },
    {
      path: '/my/rec/create',
      name: 'myRecCreate',
      component: () => import('../views/MyRecCreate.vue')
    },
    {
      path: '/recSettings',
      name: 'recSettings',
      component: () => import('../views/RECSetting.vue')
    },
    {
      path: '/nft/collect/detail',
      name: 'nftCollectDetail',
      component: () => import('../views/NFTCollectDetail.vue')
    },
    {
      path: '/owner/nft/detail',
      name: 'ownerNftDetail',
      component: () => import('../views/OwnerNftDetail.vue')
    },
    {
      path: '/my/nft/sell',
      name: 'myNftSell',
      component: () => import('../views/MyNFTSell.vue')
    },
    {
      path: '/my/nft/retire',
      name: 'myNFTRetire',
      component: () => import('../views/MyNFTRetire.vue')
    },
    {
      path: '/my/nft/retire/detail',
      name: 'myNFTRetireDetail',
      component:  () => import('../views/MyNFTRetireDetail.vue')
    },
    {
      path: '/my/orderSale',
      name: 'myOrderSale',
      component: () => import('../views/MyOrderSaleDetail.vue')
    },
    {
      path: '/my/orderSale/list',
      name: 'myOrderSaleList',
      component: () => import('../views/MyOrderSaleList.vue')
    },
    {
      path: '/my/nft/mint',
      name: 'MyNFTMint',
      component: () => import('../views/MyNFTMint.vue')
    },
    {
      path: '/my/nft/mint/detail',
      name: 'MyNftMintDetail',
      component: () => import('../views/MyNFTMintDetail.vue')
    },
    {
      path: '/my/transaction/rec',
      name: 'recTransafers',
      component: () => import('../views/RecTransferView.vue')
    },
    {
      path: '/success',
      name: 'successView',
      component: () => import('../views/SuccessView.vue')
    },
    {
      path: '/blogs',
      name: 'blogs',
      component: () => import('../views/Blog.vue')
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('../views/BlogDetail.vue')
    },
    {
      path: '/rec/detail',
      name: 'recDetail',
      component: () => import('../views/RecDetail.vue')
    },
    {
      path: '/rec/offer',
      name: 'recOffer',
      component: () => import('../views/OfferChat.vue')
    },
    {
      path: '/rec/chat',
      name: 'recChat',
      component: () => import('../views/OfferChat2.vue')
    },
    {
      path: '/subuser',
      name: 'subuser',
      component: () => import('../views/SubUser.vue')
    },
    {
      path: '/forgetpwd',
      name: 'forgetpwd',
      component: () => import('../views/ForgetPassword.vue')
    },
    {
      path: '/helpcenter/faqs',
      name: 'faqs',
      component: () => import('../views/Faqs.vue')
    },
    {
      path: '/llp/authorized',
      name: 'lianlianjump',
      component: () => import('../views/LianLianJump.vue')
    },
    {
      path: '/carbon/list',
      name: 'carbonList',
      component: () => import('../views/CarbonCreditListingView.vue')
    },
    {
      path: '/carbon/detail',
      name: 'carbonDetail',
      component: () => import('../views/CarbonCreditDetail.vue')
    },
    {
      path: '/carbon/chat',
      name: 'carbonChat',
      component: () => import('../views/CarbonCreditChat.vue')
    }
  ]
});

router.beforeEach((to, from, next) => {
  console.log(from, to, useRouterStore().lastRoute);
  // if (
  //   from.fullPath.startsWith('/login') ||
  //   from.fullPath.startsWith('/kyc') ||
  //   from.fullPath.startsWith('/email')
  // ) {
  //   if (to.fullPath === '/') {
  //     router.push(useRouterStore().lastRoute);
  //     return;
  //   }
  // } else {
  //   useRouterStore().lastRoute = from;
  // }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
});

export default router;
