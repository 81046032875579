
import { mande } from 'mande';
import { API_MEMBERSHIP_CLIENT_ACTIVE, API_MEMBERSHIP_CLIENT_ORDRES, API_MEMBERSHIP_ORDER, API_MEMBERSHIP_PLAN_ACTIVE, API_MEMEBERSHIP_CLIENT_ORDER } from './../utils/const';
import type { BaseResponse, CreateMembershipOrderRequest, CreateMembershipOrderResponse } from './../model/reqRes';
import type { MembershipPlan, MembershipOrder } from '@/model/model';

const activeMembershipPlanApi = ()=> mande(API_MEMBERSHIP_PLAN_ACTIVE)
const membershipOrderApi = ()=> mande(API_MEMBERSHIP_ORDER)
const membershipOrderClientActiveApi = () => mande(API_MEMBERSHIP_CLIENT_ACTIVE)
const membershipOrderClientApi = () => mande(API_MEMEBERSHIP_CLIENT_ORDER)
const membershipOrderClientListApi = () => mande(API_MEMBERSHIP_CLIENT_ORDRES)

export async function getClientMembershipOrders() {
    return await membershipOrderClientListApi().get<BaseResponse<MembershipOrder[]>>("").catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<MembershipOrder[]>; 
    });
}

export async function getMembershipClientOrder(id: string) {
    return await membershipOrderClientApi().get<BaseResponse<MembershipOrder>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<MembershipOrder>; 
    });
}

export async function getActiveMembershipPlan(){
    return await activeMembershipPlanApi().get<BaseResponse<MembershipPlan>>('').catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<MembershipPlan>; 
    });
}

export async function createMembershipOrder(req: CreateMembershipOrderRequest){
    return await membershipOrderApi().post<BaseResponse<CreateMembershipOrderResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<CreateMembershipOrderResponse>; 
    });
}

export async function getClientMembershipActiveOrder(){
    return await membershipOrderClientActiveApi().get<BaseResponse<MembershipOrder>>('').catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<MembershipOrder>; 
    });
}