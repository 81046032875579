import {
  checkLianLianId,
  checkUserLianLianInfo,
  createUser,
  getUserKycStatus,
  getUserLianLianStatus,
  resendEmailOTP, sendEmailOTP,
  userLogin,
  verify2FA,
  verifyEmail, verifyForgetEmail
} from '@/api/user'
import type {EmailVerifyRequest, LoginRequest, RegisterRequest} from '@/model/reqRes'
import { HttpResultEnum } from '@/utils/enums/httpEnum';
import { defineStore } from 'pinia';
import type { Verify2FARequest } from '../../model/reqRes';
import type { CorporationKYC, UserCorporationKYC, UserIndividualKYC } from '../../model/model';
import { useWeb3Store } from './web3';
import { removeGlobalToken } from '@/api/global';
import { setGlobalToken } from './../../api/global';
import type { CheckLianLianIdRequest } from './../../model/reqRes';
import { observer } from '@/utils/Observable';
import { getClientMembershipActiveOrder } from '@/api/membership';
import type { MembershipOrder } from '@/model/model';
import { useToast } from 'vue-toastification';

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    showPaymentBindingModal: false,
    agreementUrl: '',
    token: '',
    remember: false,
    refresh_token: '',
    user_id: 0,
    account_member: 0,
    lianlian_id: '',
    user_name: '',
    user_type: '',
    wallet_address: '',
    email: '',
    status: '',
    kycStatus : '',
    lianlianStatus: '',
    lianlianKycStatus: '',
    lianlianEmail: '',
    lianlianName: '',
    join_date: '',
    created_at: '',
    stored_wallet_address: '',
    user_corporation_kyc: {} as CorporationKYC,
    user_individual_kyc: {} as UserIndividualKYC,
    membershipOrder: {} as MembershipOrder,
    root: 0
  }),
  getters: {
    isLogin(state) {
      return state.user_id && state.user_id > 0;
    },
    hasMembership(state) {
      return state.membershipOrder.status === 'Active'
    },
    passKYC(state) {
      return state.kycStatus === 'KYCComplete'
    }
    // token: function (state){
    //     return state.token
    // }
  },
  actions: {
    async registerUser(user: RegisterRequest) {
      var resp = await createUser(user);
      if (resp.code == HttpResultEnum.SUCCESS && resp.data != null) {
        const data = resp.data;
        this.token = data;
        setGlobalToken(this.token);
      }
      return resp;
    },
    async verifyForgetEmailAction(code:string, email:string) {
      let req :EmailVerifyRequest = {
        code: code,
        email: email
      }
      var resp = await verifyForgetEmail(req);
      if (resp.code == HttpResultEnum.SUCCESS) {
        return ''
      } else {
        return 'OTP code is not match, please retry'
      }
    },
    async verifyEmailAction(code:string, remember: boolean) {
      let req :EmailVerifyRequest = {
        code: code,
        remember: remember
      }
      var resp = await verifyEmail(req);
      if (resp.code == HttpResultEnum.SUCCESS && resp.data != null) {
        var data = resp.data;
        this.token = data.token;
        this.refresh_token = data.refresh_token
        this.user_id = data.user.id;
        this.account_member = data.user.account_number;
        this.user_name = data.user.user_name;
        this.user_type = data.user.user_type;
        this.email = data.user.email;
        this.status = data.user.status;
        this.kycStatus = data.user.kyc_status;
        this.lianlianStatus = data.user.lianlian_status;
        this.wallet_address = data.user.wallet_address;
        this.lianlian_id = data.user.lianlian_id;
        this.lianlianEmail = data.user.lianlian_email;
        this.lianlianKycStatus = data.user.lianlian_kyc_status;
        this.lianlianName = data.user.lianlian_name;
        this.created_at = data.user.created_at;
        this.stored_wallet_address = data.user.wallet_address;
        this.user_corporation_kyc = data.user.corporation_kyc;
        this.user_individual_kyc = data.user.individual_kyc;
        this.root = data.user.root
        setGlobalToken(this.token);
        observer.addObserver(this.token);
        await this.getClientActiveMembership()
        return '';
      } else {
        return 'OTP code is not match, please retry';
      }
    },

    async resendEmailOTPAction() {
      var resp = await resendEmailOTP();
      if (resp.code == HttpResultEnum.SUCCESS) {
        return resp.data;
      }
      return '';
    },

    async checkLianLianStatus(){
      const resp = await checkUserLianLianInfo()
      if(resp.code == 200){
        this.lianlianKycStatus = resp.data?.kyc_status!
        this.lianlianStatus = resp.data?.status!
        this.lianlianEmail = resp.data?.lianlian_email!
        this.lianlianName = resp.data?.lianlian_name!
        this.lianlian_id = resp.data?.lianlian_id!
      }
    },
    async sendEmailOTPAction(email : string) {
      let req : LoginRequest = {
        email: email,
      }
      var resp = await sendEmailOTP(req);
      if (resp.code == HttpResultEnum.SUCCESS) {
        return resp.data;
      }
      return '';
    },
    async checkLianLianPay(){
      const resp = await getUserLianLianStatus()
      console.log(resp)
      if(resp.code == 200){
        this.lianlianKycStatus = resp.data?.kyc_status!
        this.lianlianStatus = resp.data?.status!
        this.lianlianEmail = resp.data?.lianlian_email!
        this.lianlianName = resp.data?.lianlian_name!
        this.lianlian_id = resp.data?.lianlian_id!
      } else {
        const toast = useToast()
        toast.error('Get LianLian Status Error')
        return false
      }
      if(this.lianlianStatus == 'NONE'){
        this.showPaymentBindingModal = true
        return false
      }
      return true 
    },
    async checkPaymentReady(redirect_url: string) {
      if(this.lianlian_id.length > 0) {
        return true
      }
      /*var req: CheckLianLianIdRequest = {
        redirect_url: encodeURIComponent(redirect_url)
      };
      const resp = await checkLianLianId(req);
      console.log(resp);
      if (resp.code == 200) {
        if (resp.data!.lianlian_id.length > 0) {
          this.lianlian_id = resp.data!.lianlian_id;
          return true;
        } else if (resp.data!.new_agreement_link.length > 0) {
          console.log('show lianlian payment', this.showPaymentBindingModal);
          this.showPaymentBindingModal = true;
          this.agreementUrl = resp.data!.new_agreement_link;
        }
      } else {
        const toast = useToast()
        toast.error(resp.message)
      }*/
      this.showPaymentBindingModal = true
      return false;
    },
    async login(email: string, password: string, remember: boolean, captcha: string) {
      this.remember = remember
      var req: LoginRequest = {
        email: email,
        password: password,
        remember: remember,
        captcha: captcha
      };
      var resp = await userLogin(req);
      console.log(resp)
      if (resp.code == HttpResultEnum.SUCCESS && resp.data != null) {
        this.token = resp.data;
        setGlobalToken(this.token);
        // observer.addObserver(this.token);
      }
      return resp;
    },
    async verify(user_id: number, code: string) {
      var req: Verify2FARequest = {
        user_id: user_id,
        code: code
      };
      var resp = await verify2FA(req);
      if (resp.code == HttpResultEnum.SUCCESS && resp.data != null) {
        var data = resp.data;
        this.token = data.token;
        this.refresh_token = data.refresh_token;
        this.user_id = data.user.id;
        this.user_name = data.user.user_name;
        this.user_type = data.user.user_type;
        this.email = data.user.email;
        this.status = data.user.status;
        this.join_date = data.user.created_at;
        this.user_corporation_kyc = data.user.corporation_kyc;
        this.user_individual_kyc = data.user.individual_kyc;
        setGlobalToken(this.token);
        await this.getClientActiveMembership()
        await this.checkUserKYCStatus()
        return '';
      } else {
        return resp.message;
      }
    },
    async getClientActiveMembership(){
      const resp = await getClientMembershipActiveOrder()
      console.log(resp)
      if(resp.code == 200){
        this.membershipOrder = resp.data!
      } else {
        this.membershipOrder = {} as MembershipOrder
      }
    },
    async checkUserKYCStatus(){
      const resp = await getUserKycStatus()
      console.log(resp)
      if(resp.code == 200){
        this.kycStatus = resp.data!
      }
    },
    async memberOnlyCheck() {
      if(!this.hasMembership) {
        await this.getClientActiveMembership()
        if(!this.hasMembership){
          const toast = useToast()
          toast.error("You need to sign up for a membership plan first")
          return false 
        }
      }
      const web3Store = useWeb3Store()
      const web3Success = await web3Store.checkWalletReady()
      if(!web3Success){
        return false
      }
      const lianlianPaySuccess = await this.checkPaymentReady(window.location.href)
      if(!lianlianPaySuccess){
        return false
      }
      return true
    },
    async kycOnlyCheck(){
      console.log('kyc check', this.passKYC)
      if(!this.passKYC){
        await this.checkUserKYCStatus()
        if(!this.passKYC){
          const toast = useToast()
          toast.error("Please note that you will need to complete your KYC before subscribing to membership plan")
          return false
        }
      }
      const web3Store = useWeb3Store()
      const web3Success = await web3Store.checkWalletReady()
      if(!web3Success){
        return false
      }
      const lianlianPayStatus = await this.checkLianLianPay()
      console.log(lianlianPayStatus)
      if(!lianlianPayStatus){
        return false 
      }
      /*const lianlianPaySuccess = await this.checkPaymentReady(window.location.href)
      if(!lianlianPaySuccess){
        return false
      }*/
      return true
    },
    signOut() {
      this.$reset();
      const web3Store = useWeb3Store();
      web3Store.$reset();
      removeGlobalToken();
      observer.close();
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'account',
        storage: localStorage
      }
    ]
  }
});
