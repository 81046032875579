<script lang="ts" setup>import { onMounted, onUnmounted, ref } from '@vue/runtime-core';

const scrollTop = ref<number>(0)
const onScroll = () => {
  scrollTop.value = window.scrollY
}

function gotoRec(){
  window.location.href = '/rec/list'
}

function gotoCC(){
  window.location.href = '/carbon/list'
}

function gotoNft(){
  window.location.href = '/nft/market'
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<template>
  <!--<div
    class="w-full bg-cover -mt-28"
    style="background-image: url(/images/index-bg.png)"
  >-->
  <div
    class="w-full 2xl:h-[50vh] bg-cover -mt-28 bg-[url('/images/top_bg.jpg')]"
  >
    <div class="h-full bg-black/10">
      <div class="h-36"></div>
      <div class="px-4 pt-4 pb-12 mx-auto xl:px-0 xl:container xl:max-w-7xl">
        <div class="sm:flex sm:flex-wrap-reverse sm:h-70">
          <div class="sm:flex-1 mx-2 mt-3 text-white">
            <h1 class="text-2xl sm:text-4xl font-bold">
              Renewable Energy Certificate (REC) 
            </h1>
            <h1 class="text-2xl sm:text-4xl font-bold mt-2">Carbon Credit (CC)</h1>
            <h1 class="text-2xl sm:text-4xl font-bold mt-2">NFT Marketplace</h1>
            <p class="mt-5 mb-12 text-lg">
              Grex is the world's first and largest REC NFT marketplace
            </p>
            <!--<button class="h-12 text-lg rounded-lg mx- px-7 bg-mainGreen" @click="gotoNft">
              Explore NFT
            </button>
            <button @click="gotoCC"
              class="h-12 ml-4  text-lg bg-white rounded-full text-mainGreen px-7"
            >
              Explore Carbon Credit
            </button>
            <button @click="gotoRec"
              class="h-12 ml-4 text-lg bg-white rounded-full text-mainGreen px-7"
            >
              Explore REC
            </button>-->
          </div>
          <div class="w-1/2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
