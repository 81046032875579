import type { CarbonCredit, CarbonCreditChat, CarbonCreditOffer } from '@/model/model';
import type { CarbonMarketSearchResponse, BaseResponse, CarbonMarketSearchRequest, PersonCarbonCreditRequest, PersonCarbonCreditResponse, NewCarbonCreditOfferRequest, PersonCarbonCreditOfferRequest, PersonCarbonCreditOfferResponse } from '@/model/reqRes';
import { API_CARBON_CREDIT } from '@/utils/const';
import { mande } from 'mande';
import { exp } from 'mathjs';

const carbonApi = () => mande(API_CARBON_CREDIT)

export async function createCarbonCredit(req: CarbonCredit) {
    return carbonApi().post<BaseResponse<CarbonCredit>>(req).catch(error => {
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCredit>;
    });
}

export async function updateCarbonCredit(req: CarbonCredit) {
    return carbonApi().put<BaseResponse<CarbonCredit>>(req).catch(error => {
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCredit>;
    });
}

export async function markSoldCarbonCredit(id:number) {
    return carbonApi().get<BaseResponse<CarbonCredit>>(`/sold/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCredit>;
    });
}

export async function cancelListingCarbonCredit(id:number) {
    return carbonApi().get<BaseResponse<CarbonCredit>>(`/cancel/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCredit>;
    });
}

export async function personSearchCarbonCredit(req: PersonCarbonCreditRequest) {
    return carbonApi().post<BaseResponse<PersonCarbonCreditResponse>>('/person/search',req).catch(error => {
        return { code: 500, message: 'server error', data: null } as BaseResponse<PersonCarbonCreditResponse>;
    });
}

export async function getTopCarbonCredits() {
    return carbonApi().get<BaseResponse<CarbonCredit[]>>('/top').catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCredit[]>;
    });
}

export async function carbonCreditMarketSearch(req: CarbonMarketSearchRequest) {
    return carbonApi().post<BaseResponse<CarbonMarketSearchResponse>>('/market/search', req).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonMarketSearchResponse>;
    });
}

export async function getCarbonCredit(id: string) {
    return carbonApi().get<BaseResponse<CarbonCredit>>(`/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCredit>;
    });
}

export async function getCarbonCreditOffer(id: string) {
    return carbonApi().get<BaseResponse<CarbonCreditOffer>>(`/offer/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditOffer>;
    });
}

export async function newCarbonCreditOffer(req: NewCarbonCreditOfferRequest) {
    return carbonApi().post<BaseResponse<CarbonCreditOffer>>(`/offer`, req).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditOffer>;
    });
}

export async function getOffersByCarbonCreditId(id:number) {
    return carbonApi().get<BaseResponse<CarbonCreditOffer[]>>('/offers/' + id).catch(error => {
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditOffer[]>;
    });
}

export async function carbonCreditOfferAccept(id:number) {
    return carbonApi().get<BaseResponse<CarbonCreditOffer>>(`/offer/accept/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditOffer>;
    });
}

export async function carbonCreditOfferReject(id:number) {
    return carbonApi().get<BaseResponse<CarbonCreditOffer>>(`/offer/reject/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditOffer>;
    });
}

export async function carbonCreditOfferCancel(id:number) {
    return carbonApi().get<BaseResponse<CarbonCreditOffer>>(`/offer/cancel/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditOffer>;
    });
}

export async function personOfferSearch(req: PersonCarbonCreditOfferRequest){
    return carbonApi().post<BaseResponse<PersonCarbonCreditOfferResponse>>('/offer/person/search', req).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<PersonCarbonCreditOfferResponse>;
    });
}

export async function createCarbonCreditChat(req: CarbonCreditChat) {
    return carbonApi().post<BaseResponse<CarbonCreditChat>>(`/chat`, req).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditChat>;
    });
}

export async function getCarbonCreditChatByOfferId(id:string) {
    return carbonApi().get<BaseResponse<CarbonCreditChat[]>>(`/chat/${id}`).catch(error => {   
        return { code: 500, message: 'server error', data: null } as BaseResponse<CarbonCreditChat[]>;
    });
}