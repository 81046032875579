<script lang="ts" setup>import { computed } from '@vue/runtime-core';

const props = defineProps({
    category: String
})
const imgUrl = computed(() => {
    if(props.category === 'Solar'){
        return '/images/solar.png'
    } else if (props.category === 'Wind'){
        return '/images/wind.png'
    } else if (props.category === 'Hydro'){
        return '/images/hydro.png'
    } else if (props.category === 'Biomass') {
        return '/images/biomass.png'
    } else if(props.category === 'Geothermal'){
        return '/images/geothermal.png'
    }
    else {
        return '/images/nft_default.png'
    }
})
</script>

<template>
    <img class="object-fill" :src="imgUrl"/>
</template>